body {
  background-color: white;
  margin: 0 auto;
  font-family: proxima-nova, sans-serif;
  max-width: 600px;
  font-size: 1.2em;
  line-height: 1.6;
  color: #222;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 100px 0;

  @media only screen and (max-width: 760px) {
    margin: 40px 50px;
  }

  > section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

h2 {
  margin: 0;
  font-weight: 500;
  font-size: 2em;

  > a {
    color: #222;
  }
}

.line {
  height: 2px;
  width: 100px;
  background-color: #dfdfdf;
  margin: 20px 0;
}

a {
  cursor: pointer;
  text-decoration: none;
}

p > a {
  color: #555;
  font-weight: 500;
  transition: color ease-in-out 0.4s;

  &:hover {
    color: #111;
  }
}

p {
  color: #777;
  font-weight: 100;
  font-size: 0.9em;
  margin-bottom: 10px;
}
